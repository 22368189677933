<template>
	<span v-if="profile">{{ profile.DisplayName }}</span>
	<span v-else><skeleton-text :lines="1"></skeleton-text></span>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
	name: "UserAvatarFullname",
	props: {
		UserId: {
			type: String,
		},
	},
	data() {
		return { profile: null };
	},
	computed: {
		...mapGetters(["UserPublicProfiles"]),
	},
	watch: {
		UserId: {
			immediate: true,
			async handler(newVal) {
				if (newVal) {
					this.profile = this.UserPublicProfiles[newVal];
					if (!this.profile) {
						await this.LoadUserPublicProfile(newVal);
						this.profile = this.UserPublicProfiles[newVal];
					}
				}
			},
		},
	},
	methods: {
		...mapActions(["LoadUserPublicProfile"]),
	},
};
</script>
