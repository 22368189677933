<template>
	<div>
		<div style="max-width: 400px;">
			<v-select :items="availableProjects"
				v-model="selectedProjectId"
				item-text="NameIT"
				item-value="ProjectId"
				@change="LoadProjectHomeItems"
				label="Seleziona Progetto"
				:loading="LoadingProjects">
			</v-select>

			<v-progress-linear indeterminate v-if="LoadingHomeItems"></v-progress-linear>
			<div v-if="HomeItems">
				<v-alert type="info" v-if="HomeItems.length === 0">Nessun elemento selezionato</v-alert>
				<v-select :items="filteredShopItems" item-text="NameIT" item-value="ItemId" v-model="selectedItemId" label="Seleziona Foto">
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title>
								<v-img height="100" aspect-ratio="1.6" contain :src="item.ImagePreviewUrl"></v-img>
							</v-list-item-title>
						</v-list-item-content>
					</template>
				</v-select>
				<v-btn @click="addSelectedItem">{{ $t("common.add") }}</v-btn>
				<v-list v-for="item in HomeItems" :key="item.ItemId">
					<v-list-item>

						<v-list-item-title>
							<v-img height="150" aspect-ratio="1.6" contain :src="item.ShopItem.ImagePreviewUrl"></v-img>
						</v-list-item-title>
						<v-list-item-action>
							<v-btn icon color="error" @click="deleteHomePageItem(item.HomePageItemId)"><v-icon>fas fa-trash</v-icon></v-btn>
						</v-list-item-action>
					</v-list-item>
				</v-list>

			</div>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
export default {
	data() {
		return {
			LoadingProjects: false,
			availableProjects: null,
			selectedProjectId: null,
			LoadingHomeItems: false,
			HomeItems: null,
			ShopItems: null,
			selectedItemId: null
		}
	},
	computed: {
	// 	.The some method tests whether at least one element in the array passes the test implemented by the provided function.
		filteredShopItems() {
			return this.ShopItems.filter(item => {
				return !this.HomeItems.some((f) => {
					return f.ItemId === item.ItemId
				})
			});
		}
	},
	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
		this.ProjectHomeItemsService = new CrudClient("Shop/HomeSelectedItems");
		this.ShopItemsService = new CrudClient("Shop/Items");
	},
	async mounted() {
		await this.LoadProjects();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		async LoadProjects() {
			try {
				this.LoadingProjects = true;
				const res = await this.ProjectsService.GetPaged({
					limit: 0,
					fields: "*"
				});

				this.availableProjects = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingProjects = false;
			}
		},

		async LoadProjectHomeItems() {
			if (!this.selectedProjectId) { return; }

			try {
				this.LoadingHomeItems = true;
				const res = await this.ProjectHomeItemsService.GetPaged({
					limit: 0,
					fields: "*, ShopItem.ImagePreviewUrl",
					urlPostFix: "Project/" + this.selectedProjectId
				});

				this.HomeItems = res.Data;

				const shopItemsRes = await this.ShopItemsService.GetPaged({
					limit: 0,
					fields: "*",
					filter: "ProjectId:" + this.selectedProjectId + ";IsActive:true"
				});

				this.ShopItems = shopItemsRes.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingHomeItems = false;
			}
		},

		async addSelectedItem() {
			const sortOrder = this.HomeItems.length > 0 ? this.HomeItems[this.HomeItems.length - 1].SortOrder + 1 : 1;
			const dto = {
				ProjectId: this.selectedProjectId,
				ItemId: this.selectedItemId,
				SortOrder: sortOrder
			}
			await this.ProjectHomeItemsService.Post(null, dto);

			await this.LoadProjectHomeItems();
		},

		async deleteHomePageItem(homePageItemId) {
			await this.ProjectHomeItemsService.Delete(homePageItemId);

			await this.LoadProjectHomeItems();
		}
	}
}
</script>
