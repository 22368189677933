<template>
	<v-dialog :value="value" @input="$emit('input', $event)" max-width="500px" @keydown.esc="CloseDialog" persistent
		no-click-animation>
		<SimpleFormCard ref="formEditShopItemVersion" :formContent="formEditShopItemVersion" :metadata="metadata"
			:metadataLoaded="metadataLoaded" :mode="mode" @submitForm="AddOrSave" :showCancelButton="false"
			:locali18n="$i18n" :initialValues="versionDetails">
			<template slot="card-title">
				<div class="primary white--text px-5 py-3 d-flex align-center justify-space-between">
					<span class="headline" style="word-break: normal">{{
						mode == 1
						? $t("addFtpConnection_Header")
						: $t("editFtpConnectionDialog_Header")
					}}</span>
					<v-btn icon dark @click="CloseDialog">
						<v-icon>close</v-icon>
					</v-btn>
				</div>
			</template>
		</SimpleFormCard>
	</v-dialog>
</template>
<script>
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";
import metadata from "@/mixins/metadata";

export default {
	$_veeValidate: { validator: "AddEditFtpConnection" },
	components: { SimpleFormCard },
	mixins: [metadata],
	data() {
		return {
			formEditShopItemVersion: new CrudFormData("formEditShopItemVersion", [
				"NameIT",
				"NameEN",
				{
					type: "v-text-field",
					"input-type": "number",
					FieldName: "SortOrder",
				},
				{
					type: "v-checkbox",
					FieldName: "IsActive",
				},
				{
					type: "v-checkbox",
					FieldName: "IsDigitalCopy",
				},
				{
					type: "v-text-field",
					"input-type": "number",
					FieldName: "Price",
				},
				{
					type: "v-text-field",
					"input-type": "number",
					FieldName: "MaxAvailability",
				},
				{
					type: "v-text-field",
					"input-type": "number",
					FieldName: "RemainingAvailability",
				},
				{
					type: "v-text-field",
					"input-type": "number",
					FieldName: "Height",
				},
				{
					type: "v-text-field",
					"input-type": "number",
					FieldName: "Width",
				},
				{
					type: "v-text-field",
					"input-type": "number",
					FieldName: "PassepartoutWidth",
				},
			]),

			versionDetails: {},
			uploadedFile: null,
		};
	},
	props: {
		value: { type: Boolean, required: true },
		mode: { type: Number, required: true },
		ItemId: { type: Number },
		VersionId: { type: Number }
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			if (!val) this.ClearNewUserDialogForm();
		},
		VersionId: async function (val) {
			if (val) {
				this.versionDetails = await this.ShopItemVersionsService.Get(val);
			}
		},
	},
	created() {
		this.ShopItemVersionsService = new CrudClient("Shop/ItemVersions");
	},
	async mounted() {
		await this.LoadMetadata(this.ShopItemVersionsService);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async AddOrSave(dto) {
			this.formEditShopItemVersion.FormLoading = true;

			const imageUpload = dto.ImagePath;
			// do not send this field in dto
			dto.ImagePath = undefined;
			this.$log.debug(imageUpload);
			try {
				if (this.mode === 1) {
					dto.ItemId = this.ItemId;
					const newVersionId = await this.ShopItemVersionsService.Post(null, dto, null, true);
					this.$log.debug(newVersionId + " created");
					this.snackSuccess({ Text: this.$t("common.success.addItem") });
				} else {
					await this.ShopItemVersionsService.Patch(this.VersionId, dto, false);
					this.snackSuccess({ Text: this.$t("common.success.updateData") });
				}
			} catch (error) {
				this.$log.error(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.formEditShopItemVersion.FormLoading = false;
				this.CloseDialog();
				this.$emit("updated");
			}
		},

		ClearNewUserDialogForm() {
			if (this.$refs.formEditShopItemVersion) {
				this.$refs.formEditShopItemVersion.ResetForm();
			}
		},

		CloseDialog() {
			this.$emit("input", false);
		},

	}
};
</script>
<i18n>
{
	"it": {
		"addFtpConnection_Header": "Nuovo foto",
		"editFtpConnectionDialog_Header": "Modifica info foto",
		"formEditShopItemVersion":
		{
			"NameIT" : "Nome (Italiano)",
			"NameEN" : "Nome (English)",
			"ContentIT" : "Didascalia (Italiano)",
			"ContentEN" : "Didascalia (English)",
			"Height": "Altezza (cm)",
			"Width": "Larghezza (cm)",
			"SortOrder": "Ordine",
			"IsActive": "Attivo?",
			"IsDigitalCopy": "Prodotto digitale?",
			"Price": "Prezzo",
			"MaxAvailability": "Tiratura",
			"RemainingAvailability": "Disponbilità rimasta",
			"PassepartoutWidth": "Passepartout (cm)"
		}
	}
}
</i18n>
