<template>
	<v-card :to="`/Shop/Orders/${order.OrderId}`">
		<v-card-text>
			<span class="text-overline">
			{{ $t("sgpshop.order") }} Id {{ order.OrderId }}</span>
			<v-chip	dark label color="primary darken-2" class="my-2">
				{{ $t("sgpshop.orderStatus") }}: {{ order.ShopOrderStatus['Name' + LanguageCodeSuffix] }}
			</v-chip>
			<template>
				<v-chip
					v-if="!order.ExternalInvoiceId"
					@click="showExternalInvoiceIdDialog = true"
					color="warning"
					class="ml-2"
					>da fatturare</v-chip
				>
				<v-chip
					v-else
					@click="showExternalInvoiceIdDialog = true"
					color="green darken-1"
					dark
					class="ml-2"
					>Id Consegna: {{ order.ExternalInvoiceId }}
				</v-chip>
			</template>
			<ExternalInvoiceIdDialog
				:Mode="2"
				:ItemId="order.OrderId"
				v-model="showExternalInvoiceIdDialog"
				@updated="$emit('updated')"
				:OrderType="2"
				:initialValues="order"
			>
			</ExternalInvoiceIdDialog>
			<v-container fluid>
				<v-row>
					<v-col cols="12" md="6">
							<span class="font-weight-bold pr-1">{{ $t("sgp.client") }}: </span>
							<UserPublicProfileName :UserId="order.UserId"></UserPublicProfileName>
							<br />
							<span class="font-weight-bold pr-1">{{ $t("orderDate") }}:</span>
							{{ order.OrderTime | formatLocalDate }}
							<br />
							<span class="font-weight-bold pr-1">{{ $t("itemsTotal") }}:</span>
							{{ order.ItemsTotal | formatCurrency }}
							<br />
							<span class="font-weight-bold pr-1">{{ $t("shippingPrice") }}:</span>
							{{ order.ShippingCost | formatCurrency }}
							<br />
							<span class="font-weight-bold pr-1">{{ $t("common.total") }}:</span>
							{{ order.Total | formatCurrency }}
					</v-col>
					<v-col cols="12" md="6">
						<v-img v-for="item in order.ShopOrderItems" :key="item.ItemId"
										class="rounded elevation-5"
										max-width="200" contain
										:src="item.ShopItemVersion.ShopItem.ImagePreviewUrl">
							</v-img>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
	</v-card>
</template>
<script>
import ExternalInvoiceIdDialog from "@/views/Events/components/AddEditExternalInvoiceIdDialog.vue";
import UserPublicProfileName from "../../components/Shared/UI/UserPublicProfileName.vue";
export default {
	components: { ExternalInvoiceIdDialog, UserPublicProfileName },
	props: {
		order: { type: Object, required: true }
	},
	data() {
		return {
			showExternalInvoiceIdDialog: false
		}
	},
	computed: {
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		}
	},
}
</script>
<i18n>
	{
		"it":{
			"itemsTotal": "Subtotale articoli",
			"orderDate": "Data ordine",
			"shippingPrice": "Spedizione"
		}
	}
</i18n>
