<template>
	<v-dialog
		:value="value"
		@input="$emit('input', $event)"
		max-width="500px"
		@keydown.esc="CloseDialog"
		persistent
		no-click-animation
	>
		<SimpleFormCard
			ref="formExternalInvoiceId"
			:formContent="formExternalInvoiceId"
			:metadata="metadata"
			:metadataLoaded="metadataLoaded"
			:initialValues="initialValues"
			@submitForm="FormAction"
			:showCancelButton="false"
			:locali18n="$i18n"
		>
			<template slot="card-title">
				<div
					class="
						primary
						white--text
						px-5
						py-3
						d-flex
						align-center
						justify-space-between
					"
				>
					<span class="headline" style="word-break: normal">{{
						Mode === 1 ? $t("addDialogHeader") : $t("editDialogHeader")
					}}</span>
					<v-btn icon dark @click="CloseDialog">
						<v-icon>close</v-icon>
					</v-btn>
				</div>
			</template>
		</SimpleFormCard>
	</v-dialog>
</template>
<script>
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";
import metadata from "@/mixins/metadata";

export default {
	$_veeValidate: { validator: "CreateUser" },
	components: { SimpleFormCard },
	mixins: [metadata],
	data() {
		return {
			formExternalInvoiceId: new CrudFormData("formExternalInvoiceId", [
				{
					type: "v-text-field",
					FieldName: "ExternalInvoiceId",
				},
			]),
		};
	},
	props: {
		value: { type: Boolean, required: true },
		Mode: { type: Number, default: 1 },
		ItemId: { type: Number },
		OrderType: { default: 1 }, // 1- sgpix order, 2 - shop order
		initialValues: { type: Object },
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			if (!val) this.ClearNewUserDialogForm();
		},
	},
	created() {
		this.DataService = this.OrderType === 1 ? new CrudClient("SGP/Orders") : new CrudClient("Shop/Orders");
	},
	async mounted() {
		await this.LoadMetadata(this.DataService);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async FormAction(dto) {
			this.formExternalInvoiceId.FormLoading = true;

			try {
				await this.DataService.Patch(this.ItemId, dto, false);
				this.snackSuccess({ Text: this.$t("successMessageUpdated") });
				this.$emit("updated");
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("errorMessage") });
			} finally {
				this.formExternalInvoiceId.FormLoading = false;
				this.CloseDialog();
			}
		},

		ClearNewUserDialogForm() {
			if (this.$refs.formExternalInvoiceId) {
				this.$refs.formExternalInvoiceId.ResetForm();
			}
		},

		CloseDialog() {
			this.$emit("input", false);
		},
	},
};
</script>

<i18n>
{
    "it": {
		"successMessageUpdated": "ID Consegna aggiornato",
		"addDialogHeader": "",
		"editDialogHeader": "Modifica ID Consegna",
		"formExternalInvoiceId": {
			"ExternalInvoiceId": "ID Consegna"

		}
    }
}
</i18n>
