<template>

  <div>
		<confirm ref="confirm"></confirm>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
				<v-icon class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
      </template>
    </v-breadcrumbs>
			<div v-if="ItemDetails">

				<v-container>
					<v-row>
						<v-col>
							<v-img
								class="rounded"
								:src="ItemDetails.ImagePreviewUrl"
								max-height="250" contain>
							</v-img>
						</v-col>
						<v-col>
							<EditVersionDetailsDialog
								:mode="mode"
								:ItemId="ItemId"
								:VersionId="selectedVersionId"
								v-model="showEditVersionDialog"
								@updated="LoadItemVersions"
							></EditVersionDetailsDialog>
							<div class="mb-5">
								<v-btn color="primary darken-2" @click="OpenNewVersionDialog">
									<v-icon left>fas fa-plus</v-icon>
									Aggiungi Versione
								</v-btn>
							</div>
							<div>
								<v-btn color="primary" @click="AddDefaultVersions" :loading="addingVersions">
										<v-icon left>fas fa-code-branch</v-icon>
										Aggiungi Versioni Predefiniti
							</v-btn>
							</div>
							</v-col>
					</v-row>
				</v-container>

				<v-card v-for="version in ItemDetails.ShopItemVersions"
					:key="version.VersionId"
					class="mb-3"
					:color="version.IsActive ? '' : 'grey'">
					<v-card-text>
						<span class="text-caption">Version ID</span>: {{ version.VersionId }} <br />
						<span class="text-caption">Name IT</span>: {{ version.NameIT }} <br />
						<span class="text-caption">Name EN</span>: {{ version.NameEN }} <br />
						<span class="text-caption">Dimensioni LxA cm</span>: {{ version.Width }}x{{ version.Height }} <br />
						<span class="text-caption">Passepartout</span>: {{ version.PassepartoutWidth }} <br />
						<span class="text-caption">Ordine</span>: {{ version.SortOrder }} <br />
						<span class="text-caption">Attivo</span>: {{ version.IsActive }} <br />
						<span class="text-caption">Prodotto digitale?</span>: {{ version.IsDigitalCopy }} <br />
						<span class="text-caption">Price</span>: {{ version.Price | formatCurrency }} <br />
						<span class="text-caption">Tiratura</span>: {{ version.MaxAvailability | emptyField }}
						<span class="text-caption">Disponibilità rimasta</span>: {{ version.RemainingAvailability | emptyField }}
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="error" @click="DeleteVersion(version.VersionId)"><v-icon left>fas fa-trash</v-icon>{{ $t("common.delete") }}</v-btn>
						<v-btn color="primary" @click="OpenEditVersionDialog(version.VersionId)"><v-icon left>fas fa-edit</v-icon>{{ $t("common.edit") }}</v-btn>
					</v-card-actions>
				</v-card>
			</div>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import EditVersionDetailsDialog from "../Shop/EditVersionDetailsDialog";
import Confirm from "@/components/Shared/Common/Confirm";

export default {
	components: {
		EditVersionDetailsDialog,
		Confirm
	},
	data() {
		return {
			LoadingChapters: false,
			ChapterDetails: null,
			LoadingProject: false,
			ProjectDetails: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				}],
			ItemDetails: null,
			selectedVersionId: null,
			showEditVersionDialog: false,
			mode: 1,
			addingVersions: false
		};
	},
	computed: {
		ChapterId() {
			return parseInt(this.$route.params.ChapterId);
		},
		ItemId() {
			return parseInt(this.$route.params.ItemId);
		}
	},
	watch: {
		showEditVersionDialog: async function (val) {
			this.$log.debug("showEditVersionDialog:" + val);
			if (!val) {
				this.selectedVersionId = null;
			}
		},
	},

	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
		this.ProjectItemsService = new CrudClient("Shop/Items");
		this.ProjectItemVersionsService = new CrudClient("Shop/ItemVersions");
	},
	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("ShopSettings"),
			disabled: false,
			exact: true,
			to: "/Shop",
		});
		// this.breadcrumbsItems.push({
		// 	text: this.$t("pageTitle"),
		// 	disabled: true,
		// 	exact: true,
		// 	to: "/Shop/Chapters",
		// });

		await this.LoadChapterDetails();
		await this.LoadProjectDetails(this.ChapterDetails.ProjectId);

		this.breadcrumbsItems.push({
			text: this.ProjectDetails.NameIT,
			disabled: false,
			exact: true,
			to: "/Shop/Chapters?ProjectId=" + this.ProjectDetails.ProjectId,
		});
		this.breadcrumbsItems.push({
			text: this.ChapterDetails.NameIT,
			disabled: false,
			exact: true,
			to: "/Shop/Chapter/" + this.ChapterId,
		});

		await this.LoadItemVersions();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadItemVersions() {
			this.ItemDetails = await this.ProjectItemsService.GetSelectedFields(this.ItemId, "*, ShopItemVersions.*");

			this.ItemDetails.ShopItemVersions = this.ItemDetails.ShopItemVersions
				.sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);
		},

		OpenEditVersionDialog(versionId) {
			this.selectedVersionId = versionId;
			this.mode = 2;
			this.showEditVersionDialog = true;
		},

		OpenNewVersionDialog() {
			this.selectedVersionId = null;
			this.mode = 1;
			this.showEditVersionDialog = true;
		},

		async DeleteVersion(versionId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete")
				))
			) {
				return;
			}

			try {
				await this.ProjectItemVersionsService.Delete(versionId);
				await this.LoadItemVersions();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			}
		},

		getImageDimensions(src) {
			return new Promise((resolve, reject) => {
				const img = new Image()
				img.onload = () => resolve({ height: img.height, width: img.width })
				img.onerror = reject
				img.src = src
			})
		},

		async AddDefaultVersions() {
			const imgDimensions = await this.getImageDimensions(this.ItemDetails.ImagePreviewUrl);
			const [h1, w1] = imgDimensions.height < imgDimensions.width ? [30, 40] : [40, 30]
			const [h2, w2] = imgDimensions.height < imgDimensions.width ? [50, 70] : [70, 50]
			const [h3, w3] = imgDimensions.height < imgDimensions.width ? [70, 100] : [100, 70]

			const dtoVersionSmall = {
				ItemId: this.ItemId,
				NameIT: `${h1}x${w1}cm`,
				NameEN: `${h1}x${w1}cm`,
				Height: h1,
				Width: w1,
				Price: 700,
				MaxAvailability: 10,
				RemainingAvailability: 10,
				SortOrder: 1,
				IsActive: true,
				GroupId: 4,
				IsDigitalCopy: false,
				PassepartoutWidth: 5
			};
			const dtoVersionMedium = {
				ItemId: this.ItemId,
				NameIT: `${h2}x${w2}cm`,
				NameEN: `${h2}x${w2}cm`,
				Height: h2,
				Width: w2,
				Price: 850,
				MaxAvailability: 7,
				RemainingAvailability: 7,
				SortOrder: 2,
				IsActive: true,
				GroupId: 5,
				IsDigitalCopy: false,
				PassepartoutWidth: 7.5
			};
			const dtoVersionLarge = {
				ItemId: this.ItemId,
				NameIT: `${h3}x${w3}cm`,
				NameEN: `${h3}x${w3}cm`,
				Height: h3,
				Width: w3,
				Price: 1200,
				MaxAvailability: 5,
				RemainingAvailability: 5,
				SortOrder: 3,
				IsActive: true,
				GroupId: 6,
				IsDigitalCopy: false,
				PassepartoutWidth: 10
			};

			try {
				this.addingVersions = true;

				await this.ProjectItemVersionsService.Post(null, dtoVersionSmall, null, true);
				await this.ProjectItemVersionsService.Post(null, dtoVersionMedium, null, true);
				await this.ProjectItemVersionsService.Post(null, dtoVersionLarge, null, true);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotAddItem") });
			} finally {
				this.addingVersions = false;
			}
			await this.LoadItemVersions();
		},

		async LoadChapterDetails() {
			try {
				this.LoadingChapters = true;

				this.ChapterDetails = await this.ProjectChaptersService.Get(this.ChapterId);
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingChapters = false;
			}
		},

		async LoadProjectDetails(projectId) {
			try {
				this.LoadingProject = true;
				const res = await this.ProjectsService.Get(projectId);

				this.ProjectDetails = res;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingProject = false;
			}
		},

	}
}
</script>
