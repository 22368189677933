<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
      </template>
		</v-breadcrumbs>
		<v-btn @click="OpenNewFotoDialog">Aggiungi Foto</v-btn>
		<EditFileDetailsDialog
			v-if="chapter"
			:mode="mode"
			:ItemId="selectedItemId"
			:ProjectId="chapter.ProjectId"
			:ChapterId="chapter.ChapterId"
			v-model="showEditFileInfoDialog"
			@updated="LoadChapterDetails">
		</EditFileDetailsDialog>
		<div v-if="chapter && ProjectDetails">
			<v-container>
				<v-row>

					<v-col cols="12" md="6" lg="4" v-for="item in chapter.ShopItems" :key="item.ItemId">
						<v-card :color="item.IsActive? '': 'grey'">
							<v-card-text>
							<v-img
							class="rounded"
							:src="item.ImagePreviewUrl"
							max-height="400" contain>
							</v-img>
						</v-card-text>
						<v-card-text>
							<span class="text-caption">ID</span>: {{ item.ItemId }} <br />
							<span class="text-caption">Name IT</span>: {{ item.NameIT }} <br />
							<span class="text-caption">Name EN</span>: {{ item.NameEN }} <br />
							<span class="text-caption">Content IT</span>
							<div>
								{{ item.ContentIT }}
							</div>
							<span class="text-caption">Content EN</span>
							<div>
								{{ item.ContentEN }}
							</div>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" :to="`/Shop/Chapter/${ChapterId}/${item.ItemId}/Versions`">Modifica Formati</v-btn>
							<v-btn color="primary" @click="openEditDialog(item.ItemId)">{{ $t("common.edit") }}</v-btn>
						</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import EditFileDetailsDialog from "./EditFileDetailsDialog";
export default {
	components: {
		EditFileDetailsDialog
	},
	data() {
		return {
			LoadingProject: false,
			ProjectDetails: null,
			LoadingChapters: false,
			chapter: null,
			blobs: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Shop",
					disabled: false,
					exact: true,
					to: "/Shop",
				}],
			showEditFileInfoDialog: false,
			selectedItemId: null,
			mode: 1,
		}
	},
	computed: {
		ChapterId() {
			return this.$route.params.ChapterId
		}
	},
	watch: {
		showEditFileInfoDialog: async function (val) {
			this.$log.debug("showEditFileInfoDialog:" + val);
			if (!val) {
				this.selectedItemId = null;
			}
		},
	},
	created() {
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
		this.ProjectsService = new CrudClient("Shop/Projects");
	},
	async mounted() {
		await this.LoadChapterDetails();
		await this.LoadProjectDetails(this.chapter.ProjectId);

		this.breadcrumbsItems.push({
			text: "Chapters",
			disabled: false,
			exact: true,
			to: "/Shop/Chapters?ProjectId=" + this.chapter.ProjectId,
		});
		this.breadcrumbsItems.push({
			text: this.chapter.NameIT,
			disabled: true,
			exact: true,
			to: "/Shop/Chapter/" + this.ChapterId,
		});
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		openEditDialog(itemId) {
			this.selectedItemId = itemId;
			this.mode = 2;
			this.showEditFileInfoDialog = true;
		},

		OpenNewFotoDialog() {
			this.selectedItemId = null;
			this.mode = 1;
			this.showEditFileInfoDialog = true;
		},

		async LoadProjectDetails(projectId) {
			try {
				this.LoadingProject = true;
				const res = await this.ProjectsService.Get(projectId);

				this.ProjectDetails = res;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingProject = false;
			}
		},

		async LoadChapterDetails() {
			try {
				this.LoadingChapters = true;

				this.chapter = await this.ProjectChaptersService.GetSelectedFields(this.ChapterId, "*, ShopItems.*");
				// this.blobs = await this.ProjectChaptersService.Get(this.ChapterId, "Blobs");

				this.chapter.ShopItems = this.chapter.ShopItems
					// .filter(item => item.IsActive === true)
					.sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);

				// for (let i = 0; i < this.chapter.ShopItems.length; i++) {
				// 	this.chapter.ShopItems[i].blob = this.blobs.find(b => this.chapter.ShopItems[i].ImagePath.endsWith(b.DisplayName));
				// }
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingChapters = false;
			}
		}
	}
}
</script>
