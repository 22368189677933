<template>
	<v-dialog :value="value" @input="$emit('input', $event)" max-width="500px" @keydown.esc="CloseDialog" persistent
		no-click-animation>
		<SimpleFormCard ref="formEditShopItem" :formContent="formEditShopItem" :metadata="metadata"
			:metadataLoaded="metadataLoaded" :mode="mode" @submitForm="AddOrSave" :showCancelButton="false"
			:locali18n="$i18n" :initialValues="itemDetails">
			<template slot="card-title">
				<div class="primary white--text px-5 py-3 d-flex align-center justify-space-between">
					<span class="headline" style="word-break: normal">{{
						mode == 1
						? $t("addFtpConnection_Header")
						: $t("editFtpConnectionDialog_Header")
					}}</span>
					<v-btn icon dark @click="CloseDialog">
						<v-icon>close</v-icon>
					</v-btn>
				</div>
			</template>
		</SimpleFormCard>

		<v-card v-if="ProjectId === 1">
			<v-card-title>
				NFF File
			</v-card-title>
			<v-card-text>
				<div v-if="itemDetails.NFFLink">
					<a :href="itemDetails.NFFLink" target="_blank">	{{ itemDetails.NFFLink }}<v-icon>fas fa-link</v-icon></a>
					<br />
					<v-btn color="error" @click="DeleteNffFile">{{ $t("common.delete") }}</v-btn>
				</div>
				<v-file-input label="NFF pdf" accept="application/pdf" v-model="uploadedFile"></v-file-input>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer><v-btn color="primary" @click="UploadNffFile">{{ $t("common.upload") }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";
import metadata from "@/mixins/metadata";

export default {
	$_veeValidate: { validator: "AddEditFtpConnection" },
	components: { SimpleFormCard },
	mixins: [metadata],
	data() {
		return {
			formEditShopItem: new CrudFormData("formEditShopItem", [
				{
					type: "v-file-input",
					FieldName: "ImagePath"
				},
				"NameIT",
				"NameEN",
				{
					type: "v-textarea",
					FieldName: "ContentIT",
					"auto-grow": true,
					rows: 2
				},
				{
					type: "v-textarea",
					FieldName: "ContentEN",
					"auto-grow": true,
					rows: 2
				},
				{
					type: "v-text-field",
					"input-type": "number",
					FieldName: "SortOrder",
				},
				{
					type: "v-checkbox",
					FieldName: "IsActive",
				},
			]),

			itemDetails: {},
			uploadedFile: null,
		};
	},
	props: {
		value: { type: Boolean, required: true },
		mode: { type: Number, required: true },
		ItemId: { type: Number },
		ProjectId: { type: Number },
		ChapterId: { type: Number }
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			if (!val) this.ClearNewUserDialogForm();
		},
		ItemId: async function (val) {
			this.$log.debug("watch ItemId");
			this.$log.debug(val);

			if (val) {
				this.itemDetails = await this.ShopItemsService.Get(val);
				// suppress the input field data type warning
				this.itemDetails.ImagePath = undefined;
			}
		},
	},
	created() {
		this.ShopItemsService = new CrudClient("Shop/Items");
	},
	async mounted() {
		this.$log.debug("Add/Edit SgpEvents Dialog mounted");
		await this.LoadMetadata(this.ShopItemsService);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async AddOrSave(dto) {
			this.formEditShopItem.FormLoading = true;

			const imageUpload = dto.ImagePath;
			// do not send this field in dto
			dto.ImagePath = undefined;
			this.$log.debug(imageUpload);
			try {
				if (this.mode === 1) {
					dto.ProjectId = this.ProjectId;
					dto.ChapterId = this.ChapterId;
					const newItemId = await this.ShopItemsService.Post(null, dto, null, true);
					if (imageUpload) {
						// imgFileName, imgFile, urlPostFix = "image"
						await this.ShopItemsService.UpdateImage(newItemId,
							imageUpload.name,
							imageUpload,
						);
					}
					this.snackSuccess({ Text: this.$t("common.success.addItem") });
				} else {
					await this.ShopItemsService.Patch(this.ItemId, dto, false);

					if (imageUpload) {
						// imgFileName, imgFile, urlPostFix = "image"
						await this.ShopItemsService.UpdateImage(this.ItemId,
							imageUpload.name,
							imageUpload,
						);
					}

					this.snackSuccess({ Text: this.$t("common.success.updateData") });
				}
			} catch (error) {
				this.$log.error(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.formEditShopItem.FormLoading = false;
				this.CloseDialog();
				this.$emit("updated");
			}
		},

		ClearNewUserDialogForm() {
			if (this.$refs.formEditShopItem) {
				this.$refs.formEditShopItem.ResetForm();
			}
		},

		CloseDialog() {
			this.$emit("input", false);
		},

		async DeleteNffFile() {
			try {
				await this.ShopItemsService.Delete(this.ItemId,
					"NffFile");

				this.snackSuccess({ Text: "File cancellato" });
				this.$emit("updated");

				this.itemDetails = await this.ShopItemsService.Get(this.ItemId);
			} catch (error) {
				this.snackError({ Text: this.$t("error.cannotDelete") });
			}
		},

		async UploadNffFile() {
			if (!this.uploadedFile) { return; }
			try {
				await this.ShopItemsService.UpdateImage(this.ItemId,
					this.uploadedFile.name, this.uploadedFile, "NffFile", false);

				this.snackSuccess({ Text: "File caricato" });
				this.uploadedFile = null;
				this.$emit("updated");

				this.itemDetails = await this.ShopItemsService.Get(this.ItemId);
			} catch (error) {
				this.$log.debug(error);
				this.snackError({ Text: "Impossibile caricare il file" });
			}
		}
	},
};
</script>
<i18n>
{
	"it": {
		"addFtpConnection_Header": "Nuovo foto",
		"editFtpConnectionDialog_Header": "Modifica info foto",
		"formEditShopItem":
		{
			"NameIT" : "Nome (Italiano)",
			"NameEN" : "Nome (English)",
			"ContentIT" : "Didascalia (Italiano)",
			"ContentEN" : "Didascalia (English)",
			"SortOrder": "Ordine",
			"IsActive": "Attivo?"
		}
	}
}
</i18n>
