<template>
	<div>
			<vue-headful :title="$t('pageTitle')" :description="$t('pageTitle')" />
			<v-breadcrumbs :items="breadcrumbsItems">
        <template v-slot:divider>
				<v-icon class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
        </template>
      </v-breadcrumbs>

			<div style="max-width: 400px;">
				<v-select :items="availableProjects"
					v-model="selectedProjectId"
					item-text="NameIT"
					item-value="ProjectId"
					@change="LoadProjectChapters"
					label="Seleziona Progetto"
					:loading="LoadingProjects">
				</v-select>
			</div>
			<div class="my-3">
				<v-btn large color="info" @click="NewChapterDialog"><v-icon left medium>fas fa-plus</v-icon>Nuova sezione</v-btn>
			</div>
			<v-card v-for="chapter in ProjectChapters" :key="chapter.ChapterId" class="mb-5">
				<v-card-title>{{ chapter.NameIT }}</v-card-title>
				<v-card-text>
					{{ chapter.ContentIT }}
				</v-card-text>
				<v-card-title>{{ chapter.NameEN }}</v-card-title>
				<v-card-text>
					{{ chapter.ContentEN }}
				</v-card-text>
				<v-card-actions>
					<v-btn icon :to="`./Chapter/${chapter.ChapterId}`">
						<v-icon>fas fa-images</v-icon>
					</v-btn>
					{{ chapter.ShopItems.length }}

					<v-icon class="ml-3" :color="chapter.IsActive ? 'green':'grey'">fas fa-check-circle</v-icon>
					<v-spacer></v-spacer>
					<v-btn @click="EditChapter(chapter)" color="primary">
						<v-icon left>fas fa-edit</v-icon>{{ $t("common.edit") }}</v-btn>
				</v-card-actions>
			</v-card>

			<EditChapterDetailsDialog
				v-model="showAddEditChapterDialog"
				:mode="editChapterMode"
				:ProjectId="selectedProjectId"
				:ChapterId="selectedChapterId"
				@updated="LoadProjectChapters">
			</EditChapterDetailsDialog>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import EditChapterDetailsDialog from "./EditChapterDetailsDialog"

export default {
	components: { EditChapterDetailsDialog },
	data() {
		return {
			LoadingProjects: false,
			selectedProjectId: null,
			availableProjects: null,
			LoadingChapters: false,
			ProjectChapters: null,
			LoadingProject: false,
			ProjectDetails: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				}],
			showAddEditChapterDialog: false,
			selectedChapterId: null,
			editChapterMode: 1
		}
	},
	watch: {
		showAddEditChapterDialog: async function (val) {
			this.$log.debug("showAddEditChapterDialog:" + val);
			if (!val) {
				this.selectedChapterId = null;
			}
		},
	},
	created() {
		this.ProjectsService = new CrudClient("Shop/Projects");
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
	},
	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("ShopSettings"),
			disabled: false,
			exact: true,
			to: "/Shop",
		});
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/Shop/Chapters",
		});

		await this.LoadProjects();
		if (this.$route.query.ProjectId) {
			this.selectedProjectId = parseInt(this.$route.query.ProjectId);
		} else {
			// Default project TimeToChange
			this.selectedProjectId = 1;
		}

		await this.LoadProjectChapters(this.selectedProjectId);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		NewChapterDialog() {
			this.selectedChapterId = null;
			this.editChapterMode = 1;
			this.showAddEditChapterDialog = true;
		},
		EditChapter(chapter) {
			this.selectedChapterId = chapter.ChapterId;
			this.editChapterMode = 2;
			this.showAddEditChapterDialog = true;
		},
		async LoadProjects() {
			try {
				this.LoadingProjects = true;
				const res = await this.ProjectsService.GetPaged({
					limit: 0,
					fields: "*"
				});

				this.availableProjects = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingProjects = false;
			}
		},
		async LoadProjectChapters() {
			try {
				this.LoadingChapters = true;
				const res = await this.ProjectChaptersService.GetPaged({
					limit: 0,
					fields: "*, ShopItems.*",
					filter: `ProjectId:${this.selectedProjectId}`
				});

				this.ProjectChapters = res.Data;

				for (let i = 0; i < this.ProjectChapters.length; i++) {
					this.ProjectChapters[0].ShopItems = this.ProjectChapters[0].ShopItems.sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);
				}
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingChapters = false;
			}
		},
	}
}
</script>
<i18n>
	{
		"it":{
			"ShopSettings": "Shop Settings",
			"pageTitle": "Chapters"
		}
	}
</i18n>
