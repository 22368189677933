<template>
	<div class="mb-3 text--primary">
			<span class="font-weight-bold pr-1">{{ $t("formShippingAddress.AddressTitle") }}:</span>{{ profile.AddressTitle
			}}
			<br />
			<span class="font-weight-bold pr-1">{{ $t("formShippingAddress.DeliveryAddress") }}:</span>
			<br />
			{{ profile.DeliveryAddress }}
			<br />
			{{ profile.PostalCode }}
			{{ profile.City }}
			{{ profile.Country.DescrizioneNazione }}
			<br />
			<span class="font-weight-bold pr-1">{{ $t("formShippingAddress.AdditionalInfo") }}:</span>{{
				profile.AdditionalInfo }}
		</div>
</template>
<script>
export default {
	props: {
		profile: {
			type: Object,
		},
	},
	data() {
		return {}
	}
}
</script>
<i18n>
{
	"it": {
		"formShippingAddress":{
			"DeliveryAddress": "Indirizzo di consegna",
			"AdditionalInfo": "Ulteriori informazioni",
			"AdditionalInfo_hint": "Codice, edificio, azienda",
			"AddressTitle": "Nome e cognome",
			"City": "Città",
			"Address": "Indirizzo",
			"PostalCode": "CAP",
			"CountryId": "Paese"
		},
		"alertMissingShippingAddress": "Indirizzo di consegna mancante",
		"confirmDeleteShippigAddress": "Sei sicuro di voler cancellare l'indirizzo?"
	},
	"en": {
		"formShippingAddress":{
			"DeliveryAddress": "Delivery address",
			"AdditionalInfo": "Additional address information",
			"AdditionalInfo_hint": "Code, building, company",
			"AddressTitle": "Full name",
			"City": "City",
			"Address": "Registred address",
			"PostalCode": "Postal Code",
			"CountryId": "Country"
		},
		"alertMissingShippingAddress": "No delivery addresses found",
		"confirmDeleteShippigAddress": "Are you sure you want to delete the shipping address?"
	}
}
</i18n>
