<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
          <v-icon class="secondary--text text--lighten-2"
            >fas fa-caret-right</v-icon
          >
      </template>
		</v-breadcrumbs>

		<h1 class="sgpshop-h1">{{ $t("sgpshop.orders") }}</h1>

		<v-progress-linear v-if="loadingData" indeterminate></v-progress-linear>
		<div v-else>

			<v-alert type="info" v-if="orders && orders.length === 0">
				Nessun ordine trovato
			</v-alert>

			<OrdersListCard v-for="order in orders" :key="order.OrderId" class="mb-3"
				:order="order"
				@updated="LoadShopOrders">
			</OrdersListCard>

		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import OrdersListCard from "./OrdersListCard.vue";
export default {
	components: {
		OrdersListCard
	},
	computed: {
		...mapGetters([]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		}
	},
	metaInfo() {
		return {
			title: this.$t("sgpshop.orders")
		};
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: this.$t("common.shop"),
					disabled: false,
					exact: true,
					to: "/Shop",
				},
				{
					text: this.$t("sgpshop.orders"),
					disabled: true,
					exact: true,
					to: "/Shop/Orders",
				}],
			orders: null,
			loadingData: false
		};
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadShopOrders() {
			try {
				this.loadingData = true;
				const res = await this.OrdersService.GetPaged({
					limit: 0,
					fields: "*, ShopOrderItems.*, ShopOrderStatus.*, ShopOrderItems.ShopItemVersion.ShopItem.*",
					orderBy: "OrderId:desc"
				});

				this.orders = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.loadingData = false;
			}
		}
	},
	async mounted() {
		await this.LoadShopOrders();
	},
	created() {
		this.OrdersService = new CrudClient("Shop/Orders");
	}
}
</script>
<i18n>
	{
		"it":{
			"orderDetails": "Dettaglio ordine"

		},
		"en":{
			"orderDetails": "Order Details"
		}
	}
</i18n>
