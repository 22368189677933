<template>
	<div class="mb-3 text--primary">
		<template v-if="profile.HasPartitaIVA">
			<span class="font-weight-bold pr-1">{{ $t("formBillingProfile.RagioneSociale") }}:</span
			>{{ profile.RagioneSociale }}
			<br />
			<span class="font-weight-bold pr-1">{{ $t("formBillingProfile.PartitaIVA") }}:</span
				>{{ profile.PartitaIVA }}<br />
		</template>
		<template v-if="!profile.HasPartitaIVA">
			<span class="font-weight-bold pr-1">{{ $t("formBillingProfile.Name") }}:</span
				>{{ profile.Name }}
			<br />
			<span class="font-weight-bold pr-1">{{ $t("formBillingProfile.Surname") }}:</span
				>{{ profile.Surname }}
			<br />
			<span class="font-weight-bold pr-1">{{ $t("formBillingProfile.CodiceFiscale") }}:</span
			>{{ profile.CodiceFiscale }}<br />
		</template>
		<span class="font-weight-bold pr-1">{{ $t("formBillingProfile.CodiceSDI") }}:</span
				>{{ profile.CodiceSDI }}<br />
		<span class="font-weight-bold pr-1"> {{ $t("formBillingProfile.Address") }}:</span>
		{{ profile.Address }}
		{{ profile.ComuneId ? profile.ItalianCity.Name : profile.NonItalianCityName }}
		{{ profile.PostalCode }}
		<br />
		{{ profile.CountryId ? profile.Country.DescrizioneNazione : "-" }}
	</div>
</template>
<script>
export default {
	props: {
		profile: {
			type: Object,
		},
	},
	data() {
		return {}
	}
}
</script>
<i18n>
{
	"it": {
		"formBillingProfile":{
			"companyType_Company": "Azienda",
			"companyType_Personal": "Persona Fisica",
			"RagioneSociale": "Ragione Sociale",
			"CodiceFiscale": "Codice Fiscale",
			"CodiceSDI": "Codice SDI",
			"PartitaIVA": "Partita IVA",
			"Address": "Indirizzo",
			"ComuneId": "Città",
			"PostalCode": "CAP",
			"Name": "Nome",
			"Surname": "Cognome",
			"CountryId": "Stato di residenza",
			"NonItalianCityName": "Nome città estera"
		}
	},
	"en":{
		"formBillingProfile":{
			"companyType_Company": "Business",
			"companyType_Personal": "Private",
			"RagioneSociale": "Business name",
			"CodiceFiscale": "Codice Fiscale",
			"CodiceSDI": "Codice SDI",
			"PartitaIVA": "Tax Identification Number",
			"Address": "Registred address",
			"ComuneId": "City",
			"PostalCode": "Postal Code",
			"Name": "Name",
			"Surname": "Surname",
			"CountryId": "Country",
			"NonItalianCityName": "City"
		}
	}
}
</i18n>
