<template>
	<v-dialog :value="value" @input="$emit('input', $event)" max-width="800px" @keydown.esc="CloseDialog" persistent
		no-click-animation>
		<SimpleFormCard ref="formAddEditChapter" :formContent="formAddEditChapter" :metadata="metadata"
			:metadataLoaded="metadataLoaded" :mode="mode" @submitForm="AddOrSave" :showCancelButton="false"
			:locali18n="$i18n" :initialValues="itemDetails">
			<template slot="card-title">
				<div class="primary white--text px-5 py-3 d-flex align-center justify-space-between">
					<span class="headline" style="word-break: normal">{{
						mode == 1
						? $t("addFtpConnection_Header")
						: $t("editFtpConnectionDialog_Header")
					}}</span>
					<v-btn icon dark @click="CloseDialog">
						<v-icon>close</v-icon>
					</v-btn>
				</div>
			</template>
		</SimpleFormCard>
	</v-dialog>
</template>
<script>
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";
import metadata from "@/mixins/metadata";

export default {
	$_veeValidate: { validator: "AddEditFtpConnection" },
	components: { SimpleFormCard },
	mixins: [metadata],
	data() {
		return {
			formAddEditChapter: new CrudFormData("formAddEditChapter", [
				"NameIT",
				"NameEN",
				{
					type: "v-checkbox",
					FieldName: "IsActive",
				},
				{
					type: "v-textarea",
					FieldName: "ContentIT",
				},
				{
					type: "v-textarea",
					FieldName: "ContentEN",
				},
				{
					type: "v-text-field",
					FieldName: "SortOrder",
					"input-type": "number"
				},
				{
					type: "v-text-field",
					FieldName: "VimeoVideoUrl",
				},
			]),

			itemDetails: {},
		};
	},
	props: {
		value: { type: Boolean, required: true },
		mode: { type: Number, required: true },
		ChapterId: { type: Number },
		ProjectId: { type: Number },
	},

	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			if (!val) this.ClearNewUserDialogForm();
		},
		ChapterId: async function (val) {
			this.$log.debug("watch ChapterId");
			this.$log.debug(val);

			if (val) {
				this.itemDetails = await this.ProjectChaptersService.Get(val);
			}
		},
	},
	created() {
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
	},
	async mounted() {
		await this.LoadMetadata(this.ProjectChaptersService);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async AddOrSave(dto) {
			this.formAddEditChapter.FormLoading = true;

			try {
				if (this.mode === 1) {
					dto.ProjectId = this.ProjectId;
					await this.ProjectChaptersService.Post(null, dto);
					this.snackSuccess({ Text: this.$t("common.success.addItem") });
				} else {
					await this.ProjectChaptersService.Patch(this.ChapterId, dto, false);
					this.snackSuccess({ Text: this.$t("common.success.updateData") });
				}
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.formAddEditChapter.FormLoading = false;
				this.CloseDialog();
				this.$emit("updated");
			}
		},

		ClearNewUserDialogForm() {
			if (this.$refs.formAddEditChapter) {
				this.$refs.formAddEditChapter.ResetForm();
			}
		},

		CloseDialog() {
			this.$emit("input", false);
		},
	},
};
</script>
<i18n>
{
	"it": {
		"addFtpConnection_Header": "Nuovo event",
		"editFtpConnectionDialog_Header": "Modifica event",
		"formAddEditChapter":
		{
			"NameIT" : "Nome File (IT)",
			"NameEN" : "Nome File (EN)",
			"ContentIT" : "Nome Descrizione (IT)",
			"ContentEN" : "Nome Descrizione (EN)",
			"IsActive": "Visibile?",
			"SortOrder": "Ordine",
			"VimeoVideoUrl": "Vimeo Video Url (ie https://vimeo.com/852718830/bb3debd518)"
		}
	}
}
</i18n>
